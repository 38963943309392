import {
    type CompoundDocument,
    type Resource,
    type ResourceObject,
    useNativeFetchClient,
    type Index,
    type IndexDocument,
    useJsonApiWithIndex,
    type ResourceList
} from '@jaspr/client-js';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useAuth } from '@/stores/useAuth';
import { useConfig } from '@/plugins/config';

export { type ResourceObject };
export interface CollectionMeta {
    limit: number;
    offset: number;
    total: number;
}
export interface ResourceCollectionDocument<T extends Resource> extends CompoundDocument {
    data: Array<T>;
    meta: CollectionMeta;
    // related(signal?: AbortSignal): Promise<ResourceCollectionDocument<T>>;
    // self(signal?: AbortSignal): Promise<ResourceCollectionDocument<T>>;
}

export interface ResourceDocument<T extends Resource> extends CompoundDocument {
    data: T;
    // related(signal?: AbortSignal): Promise<ResourceDocument<T>>;
    // self(signal?: AbortSignal): Promise<ResourceDocument<T>>;
}

export const useRest = defineStore('rest', () => {
    const ready = ref<boolean>(false)
    const apiBaseUrl: string = useConfig().config.value!.api;
    const {token} = storeToRefs(useAuth());

    /**
     * Index document for API.
     * @see http://dev.nc-dev.logio.in/api/gui/v1/
     */
    const index: IndexDocument<Index> = {
        'jsonapi': {
            'version': '1.0'
        },
        'links': {
            'applications': 'applications',
            'channels': 'channels',
            'notifications': 'notifications',
            'notificationTypes': 'notification-types',
            'subscriptions': 'subscriptions',
            'users': 'users',
        },
        'meta': {
            'baseUrl': '',
            'title': 'JSON:API Index Page'
        }
    };

    const init = (): void => {
            const client = useNativeFetchClient(apiBaseUrl);
            client.setHeader('Authorization', `Bearer ${token.value}`);
            ready.value = true;
    };

    // returns client
    const http = computed(() => {
        const client = useNativeFetchClient(apiBaseUrl);
        // set headers
        client.setHeader('Authorization', `Bearer ${token.value}`);
        client.setHeader('Content-Type', 'application/json');
        client.setHeader('Accept', 'application/json');
        return client;
    })

    // returns api
    const rest = computed((): ResourceList<Index> => {
        const client = useNativeFetchClient(apiBaseUrl);
        client.setHeader('Authorization', `Bearer ${token.value}`);
        return useJsonApiWithIndex<Index>(index, client);
    })

    return {
        init,
        http,
        rest,
        ready
    };
});

