import { defineStore } from 'pinia';
import Keycloak from 'keycloak-js';
import { useConfig } from '@/plugins/config';
import useLogger from '@/utils/logger';
import { ref } from 'vue';
export interface User  {
    username: string
    name: string
    surname: string
    email: string
}
export const useAuth = defineStore('auth', () => {
    const subscribers = ref<Array<any>>([]);
    const { info, error } = useLogger();

    // init keycloak instance
    const keycloak = new Keycloak({
        'clientId': useConfig().config.value!.keycloak.clientId,
        'realm': useConfig().config.value!.keycloak.realm,
        'url': useConfig().config.value!.keycloak.url
    });

    const user = ref<User>({
        username: '',
        name: '',
        surname: '',
        email: ''
    })
    const authorized = ref(false);
    const token = ref<string>('');
    let timeout = 600_000 / 4;

    const subscribeOnTokenChanged = (callback: (token: string) => void): () => void => {
        subscribers.value.push(callback);
        return (): void => {
            const index = subscribers.value.indexOf(callback);
            if (index > -1) {
                subscribers.value.splice(index, 1);
            }
        };
    };

    // refresh token
    const refresh = async (): Promise<void> => {
        await keycloak.updateToken(timeout)
    }

   /**
    * LOGIN
    */
    const login = async(): Promise<void> => {
        console.info('Auth logging in');


        // login
        await keycloak.init({
            checkLoginIframe: false,
            enableLogging: import.meta.env.DEV,
            onLoad: 'login-required'
        });

        // logout if unauthorized
        if (!authorized.value) {
            console.error('You are not authorized!');
            await logout();
        }

        // set token
        token.value = keycloak.token!;

        // set timeout and interval for refreshing token
        const ttl = (keycloak.tokenParsed?.exp ?? 0) * 1000 - Date.now();
        if (ttl > 0) {
            timeout = Math.round(ttl / 4);
        }
        setInterval(refresh, timeout);

        // load user
        await keycloak.loadUserInfo();

        const profile = keycloak.userInfo as {
            email: string;
            family_name: string;
            given_name: string;
            name: string;
            preferred_username: string;
        };

        user.value = {
            name: profile.name,
            email: String(profile.email) ?? '',
            username: profile.preferred_username ?? '',
            surname: profile.family_name ?? ''
        }
    };

    /**
     * LOGOUT
     */
    const logout = async (): Promise<void> => {
        return keycloak.logout();
    }

    keycloak.onAuthSuccess = () => {
        info('Auth logged in.');
        authorized.value = true;
    };
    keycloak.onAuthError = (errorData) => {
        authorized.value = false;
        error(errorData);
    };
    keycloak.onAuthRefreshSuccess = () => {
        authorized.value = true;
        token.value = keycloak.token!;
        info('Token refreshed.');
    };
    keycloak.onAuthLogout = () => {
        authorized.value = false;
        info('Logged out.');
    };
    keycloak.onAuthRefreshError = () => {
        authorized.value = false;
        error('Token refresh failure.');
    };

    // Logout on token expired
    keycloak.onTokenExpired = (): void => {
        void logout();
        info('Token expired.');

    };
    return { authorized, user, login, logout, subscribeOnTokenChanged, token };
});

