<template>
    <v-container fluid>
        <NotificationsSettings
            :debug="isDebug"
            :api-url="apiUrl"
            :user-name="user.username"
        />

    </v-container>
</template>
<script setup lang="ts">
import { useAuth } from '@/stores/useAuth';
import { useConfig } from '@/plugins/config'
import { computed } from 'vue';
import NotificationsSettings from '@/components/NotificationsSettings/NotificationsSettings.vue';
import { storeToRefs } from 'pinia';

// props for Notifications Settings
const { user } =  storeToRefs(useAuth());
const isDebug = computed((): boolean => import.meta.env.MODE === 'development');
const apiUrl = useConfig().config.value!.api;

</script>
