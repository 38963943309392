import App from './App.vue';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from './plugins/i18n';
import router from '@/router/index';
import sentry from '@/plugins/sentry';
import vuetify from './plugins/vuetify';
import { useConfig } from './plugins/config';

await useConfig().loadConfig()

const app = createApp(App);
const pinia = createPinia();

const instance = app
    .use(router)
    .use(pinia)
    .use(vuetify)
    .use(i18n)
    .use(sentry);
instance.mount('#app');
