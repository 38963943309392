<template>
    <img
        :src="url"
        crossorigin="anonymous"
        :alt="$t('avatar')"
    >
</template>

<script setup lang="ts">
/**
 * @file User Gravatar Component.
 * @author svehla@logio.cz
 */
import { computed } from 'vue';
import md5 from 'md5';

const props = withDefaults(defineProps<{
    email: string;
    size: number;
}>(), {
    email: () => '',
    size: () => 15
});

const url = computed(() => {
    const hash = md5(props.email.trim().toLowerCase());
    if (!props.email) {
        return `https://www.gravatar.com/avatar?s=${String(props.size)}&d=mp`;
    }
    return `https://www.gravatar.com/avatar/${hash}?s=${String(props.size)}&d=identicon&r=g`;
});
</script>
