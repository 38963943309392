/**
 * @file Loads App Config and provides it.
 */
import { ref } from 'vue';

export interface Config {
    api: string;
    keycloak: {
        clientId: string;
        realm: string;
        url: string;
    };
    name: string;
    sentryDsn: string | null;
    version: string;
}

const config = ref<Config>();

const loadConfig = async (): Promise<void> => {
    try {
        const response = await fetch(`${window.location.origin}/config.json`, { cache: 'no-store' });

        if (!response.ok) {
            console.error(`Error:${response.status}`);
            throw new Error(`Error:${response.status}`);
        }
        config.value = await response.json() as Config;

    } catch (error: any) {
        throw new Error(`Error loading config: ${error.message}`);
    }
};

const useConfig = () => ({ config, loadConfig });

export { useConfig }



