<template>
    <v-layout full-height>
        <v-app-bar
            elevation="1"
            color="primary"
        >

            <template #title>
                <v-icon :icon="mdiBellCogOutline" class="mr-2"/>
                <span>
                    {{ $t('notificationCentreTitle')}}

                </span>
            </template>
            <template #append>
                <v-btn >
                    <template #prepend>
                        <UserGravatar
                            :email="user.email"
                            :size="34"
                            class="rounded-circle"
                        />
                    </template>
                    <span class="text-subtitle-1">
                        {{ user.name }}
                    </span>
                    <v-menu activator="parent" location="bottom" >
                        <v-list density="compact">
                            <v-list-item
                                :title="$t('buttons.logout')"
                                :prepend-icon="mdiLogout"
                                slim
                                @click="onLogout"
                            />
                        </v-list>
                    </v-menu>
                </v-btn>
            </template>
        </v-app-bar>
        <v-main>
            <template v-if="authorized && ready">
                <router-view/>
            </template >
            <template v-else>
                <div class="text-center ma-10">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        :size="128"
                        :width="8"
                    />
                </div>
            </template>
        </v-main>
    </v-layout>
</template>

<script setup lang="ts">
import { mdiBellCogOutline, mdiLogout } from '@mdi/js';
import { useAuth } from '@/stores/useAuth';
import UserGravatar from '@/components/UserGravatar/UserGravatar.vue';
import { storeToRefs } from 'pinia';
import { useRest } from './stores/useRest';

// user & api
const { user, authorized } = storeToRefs(useAuth());
const { logout } = useAuth();
const { ready } = storeToRefs(useRest());

// logout
const onLogout = async (): Promise<void> => {
    await logout();
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
