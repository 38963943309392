/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
export enum StyleSlug {
    Debug = 'color: white; background: #2185d0; border-radius: 8px; padding: 2px 3px;',
    Error = 'color: white; background: #db2828; border-radius: 8px; padding: 2px 3px;',
    Info = 'color: white; background: #c4c64f; border-radius: 8px; padding: 2px 3px;',
    Log = 'color: white; background: #4f4f4f; border-radius: 8px; padding: 2px 3px;',
    Trace = 'color: white; background: #ab29db; border-radius: 8px; padding: 2px 3px;',
    Warn = 'color: white; background: #fbbd08; border-radius: 8px; padding: 2px 3px;'
}

export class Logger {
    public isDebugOn = false;

    public constructor(isDebugOn: boolean) {
        this.isDebugOn = isDebugOn;
    }

    public error(...args: any): void {
        if (this.isDebugOn) {
            console.error('%cERROR', StyleSlug.Error, ...args);
        }
    }

    public log(...args: any): void {
        if (this.isDebugOn) {
            console.log('%cLOG', StyleSlug.Log, ...args);
        }
    }

    public info(...args: any): void {
        if (this.isDebugOn) {
            console.info('%cINFO', StyleSlug.Info, ...args);
        }
    }

    public debug(...args: any): void {
        if (this.isDebugOn) {
            console.debug('%cDEBUG', StyleSlug.Debug, ...args);
        }
    }

    public warn(...args: any): void {
        if (this.isDebugOn) {
            console.warn('%cWARN', StyleSlug.Warn, ...args);
        }
    }

    public trace(...args: any): void {
        if (this.isDebugOn) {
            console.trace('%cTRACE', StyleSlug.Trace, ...args);
        }
    }
}

export const useLogger = (isDebugOn: boolean) => new Logger(isDebugOn);
