import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import { BrowserTracing } from '@sentry/tracing';
import { useAuth } from '@/stores/useAuth';
import { useConfig } from '@/plugins/config';


function createSentry() {
    const install = (app: App) => {
        const { user} = useAuth();
        const email = user.email
        const username = user.username
        const router = app.config.globalProperties.$router;
        Sentry.init({
            app,
            beforeSend(event) {
                event.user = {
                    email,
                    username
                };
                // Check if it is an exception, and if so, show the report dialog
                if (event.exception) {
                    Sentry.showReportDialog({ eventId: event.event_id });
                }
                return event;
            },

            dsn: useConfig().config.value!.sentryDsn as string,

            // release: import.meta.env.VITE_VERSION,
            environment: import.meta.env.MODE,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracePropagationTargets: ['localhost', 'dev.nc-dev.logio.in', /^\//]
                })
            ],
            logErrors: true,
            tracesSampleRate: 1.0,
            trackComponents: false
        });
    };
    return { install };
}

export default createSentry();
