/**
 * @file Translations config https://kazupon.github.io/vue-i18n/.
 * @author maskova@logio.cz
 */
import * as cs from '@/locales/cs.json';
import { createI18n, type I18nOptions } from 'vue-i18n';

// 0 aut
// 1 auto
// 2 - 4 auta
// 5 až nekonecno aut
const csPluralizationRule = (choice: number): number => {
    if (choice === 0) {
        return 0;
    }
    if (choice === 1) {
        return 1;
    }
    if (choice > 2 && choice < 5) {
        return 2;
    }
    if (choice > 4) {
        return 3;
    }
    return 0;
};

const browserLocale: Array<string> = navigator.language.split('-');
const [browserLocaleShort] = browserLocale;
const savedLanguage = localStorage.getItem('language');
const availableLocales = ['cs'];
const locale = savedLanguage ?? browserLocaleShort;
const options: I18nOptions = {
    availableLocales,
    fallbackLocale: 'cs',
    legacy: false,
    locale,
    messages: {
        cs
    },
    pluralRules: {
        cs: csPluralizationRule
    }
};
export {
    availableLocales,
    locale
};
export default createI18n(options);
