/**
 * @file Vuetify config https://vuetifyjs.com/.
 * @author maskova@logio.cz
 */
import 'vuetify/styles';
import * as labs from 'vuetify/labs/components';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { createVuetify } from 'vuetify';
import i18n from '@/plugins/i18n';
import type { ThemeDefinition } from 'vuetify';
import { useI18n } from 'vue-i18n';
import colors from 'vuetify/lib/util/colors';

const light: ThemeDefinition = {
    colors: {
        background: colors.grey.lighten4,
        default: colors.grey.lighten3,
        primary: colors.deepPurple.base,
        secondary: colors.amber.base
    },
    dark: false
};
const dark: ThemeDefinition = {
    colors: {
        primary: colors.purple.base,
        secondary: colors.amber.base
    },
    dark: true

};
const nc: ThemeDefinition = {
    colors: {
        'background': '#f2f2f2',
        'error': '#FF3458',
        'info': '#1994C1',
        'primary': '#3F51B5',
        'secondary': '#4DCA92',
        'success': '#50B246',
        'surface': '#FFFFFF',
        'warning': '#FFCD13'

    },
    dark: false
};

export default createVuetify(
    {
        components: { ...labs },
        defaults: {
            VCard: {
                elevation: 0
            }
        },
        icons: {
            aliases,
            defaultSet: 'mdi',
            sets: {
                mdi
            }
        },
        locale: {
            // @ts-expect-error abc
            adapter: createVueI18nAdapter({ i18n, useI18n })
        },
        theme: {
            defaultTheme: 'nc',
            themes: {
                dark,
                light,
                nc
            },
            variations: {
                colors: ['primary', 'secondary', 'info'],
                darken: 5,
                lighten: 5
            }
        }
    }
);
