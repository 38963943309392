/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-empty-function */
export enum Level {
    DEBUG = 100,
    INFO = 200,
    WARN = 300,
    ERROR = 400
}

interface Logger {
    debug(...data: Array<any>): void;

    error(...data: Array<any>): void;

    info(...data: Array<any>): void;

    setLevel(leve: Level): void;

    warn(...data: Array<any>): void;
}

/**
 *
 */

/**
 *
 */
export default function useLogger(): Logger {
    let _debug: any = null;
    let _info: any = null;
    let _warn: any = null;
    let _error: any = null;

    const disableDebug = (): void => {
        _debug = console.debug;
        window.console.debug = (): void => {
        };
    };
    const enableDebug = (): void => {
        if (_debug == null) {
            return;
        }
        window.console.debug = _debug;
    };
    const disableInfo = (): void => {
        _info = console.info;
        window.console.info = (): void => {
        };
    };
    const enableInfo = (): void => {
        if (_info == null) {
            return;
        }
        window.console.info = _info;
    };
    const disableWarn = (): void => {
        _warn = console.warn;
        window.console.warn = () => {
        };
    };
    const enableWarn = (): void => {
        if (_warn == null) {
            return;
        }
        window.console.warn = _warn;
    };
    const disableError = (): void => {
        _error = console.error;
        window.console.error = (): void => {
        };
    };
    const enableError = (): void => {
        if (_error == null) {
            return;
        }
        window.console.error = _error;
    };

    const setLevel = (level: Level): void => {
        [disableDebug, disableInfo, disableWarn, disableError].forEach((d) => {
            d();
        });
        const toEnable = [];
        if (level <= Level.ERROR) {
            toEnable.push(enableError as never);
        }
        if (level <= Level.WARN) {
            toEnable.push(enableWarn as never);
        }
        if (level <= Level.INFO) {
            toEnable.push(enableInfo as never);
        }
        if (level <= Level.DEBUG) {
            toEnable.push(enableDebug as never);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        toEnable.forEach((e) => e());
    };

    return { ...console, setLevel };
}
