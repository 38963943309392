<template>
        <v-table>
            <thead>
                <tr>
                    <th
                        id="blank-header"
                    />
                    <th
                        v-for="notificationChannel in notificationChannels"
                        :id="notificationChannel.id"
                        :key="notificationChannel.id"
                        class="text-center text-primary-darken-2"
                    >
                        <v-icon
                            :icon="channelIcons[notificationChannel.id]"
                        />
                        {{ notificationChannel.attributes.name }}
                    </th>
                </tr>
            </thead>
            <template
                v-if="isLoading"
            >
                <tbody class="align-center d-flex justify-center">
                    <v-progress-linear
                        indeterminate
                        color="grey lighten-1"
                    />
                </tbody>
            </template>
            <template v-else>
                <tbody>
                    <template
                        v-for="application in notificationSettingsItems"
                        :key="application.appId"
                    >
                        <tr>
                            <td
                                class="font-weight-bold d-flex align-center"
                            >

                                    <v-avatar
                                        v-if="application.img !== ''"
                                        :image="application.img"
                                        rounded="0"
                                    />
                                    <v-avatar
                                        v-else
                                        :icon="mdiApplicationOutline"
                                        rounded="0"
                                    />

                                    <span>
                                        {{ application.name }}
                                    </span>
                            </td>

                            <td
                                v-for="channel in notificationChannels"
                                :key="channel.id"
                            >
                                <v-checkbox
                                    :model-value="isAllSelectedInApp(application.appId, channel.id)"
                                    :indeterminate="isAnySelectedInApp(application.appId, channel.id)"
                                    density="compact"
                                    class="center"
                                    color="primary"
                                    hide-details
                                    @update:model-value="(value: boolean | null) => updateSubscriptionForApp(value as boolean, application.appId, channel.id, isAllSelectedInApp(application.appId, channel.id))"
                                />
                            </td>
                        </tr>
                        <tr
                            v-for="(type) in application.types"
                            :key="type.typeId"
                        >
                            <td class="text-subtitle-2">
                                <span class="ml-sm-10">
                                    {{ type.name }}
                                </span>
                            </td>
                            <td
                                v-for="subscription, i in type.subscriptions"
                                :key="i"
                            >
                                <v-checkbox
                                    class="center"
                                    :model-value="subscription"
                                    density="compact"
                                    color="primary-darken-4"
                                    hide-details
                                    @update:model-value="(value: boolean | null) => updateModelValueAndSubscription(type.appId, type.typeId, i, value as boolean)"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-table>
</template>

<script setup lang="ts">
import { type Logger, useLogger } from '@/components/Logger';
import { mdiBellOutline, mdiEmailOutline, mdiApplicationOutline, mdiCellphone } from '@mdi/js';
import { computed, onMounted, reactive, ref } from 'vue';
import { useRest } from '@/stores/useRest';
import type { PartialResource } from '@jaspr/client-js';
import type { PApplication, PChannel, PNotificationType, PSubscription } from '@/model';
import { Channel } from '../interface/NotificationChannel';
import type { NotificationSettingItem, NotificationSettingItemType } from '../interface/NotificationSetting';

// Props
const props = defineProps<{
    apiUrl: string;
    debug: boolean;
    userName: string
}>();

// Data
let logger: Logger;
let user = '';
let debounce = 0;
const isLoading = ref<boolean>(false);


// NOTIFICATIONS SETTINGS - TABLE
const notificationSettingsItems = reactive< Record<string, NotificationSettingItem>>({}); // notifications items (events) obj [user can subscribe for these in NC]

// v-models for checkboxes in template
//for application notification types
const isAllSelectedInApp = computed(() => (appId: string, channelId: string): boolean => {
    const filteredNotificationSettingItemTypes = ref(notificationSettingsItems[appId].types);
    const appNotifTypeArray = ref(Object.values(filteredNotificationSettingItemTypes.value)); // notification types Array for application
    return appNotifTypeArray.value.every((type) => type.subscriptions[channelId]);
})

// indeterminate (for app-notification-type)
const isAnySelectedInApp = computed(() => (appId: string, channelId: string): boolean => {
    const filteredNotificationSettingItemTypes = ref(notificationSettingsItems[appId].types);
    const appNotifTypeArray = ref(Object.values(filteredNotificationSettingItemTypes.value));
    return (appNotifTypeArray.value.some((type) => type.subscriptions[channelId])) && (appNotifTypeArray.value.some((type) => !type.subscriptions[channelId]));
})

// checkbox events
const updateModelValueAndSubscription = (appId: string, typeId: string, channelId: string, value: boolean): void => {
    notificationSettingsItems[appId].types[typeId].subscriptions[channelId] = value; // get value
    let subscriptionId = getSubscriptionId(typeId, channelId)// get subscription id
    updateSubscription(value, typeId, channelId, subscriptionId); // update subscription
};

const updateSubscriptionForApp = (value: boolean, appId: string, channelId: string, isAllSelectedInApp: boolean): void => {
    window.clearTimeout(debounce);
    Object.values(notificationSettingsItems[appId].types).forEach((type) => type.subscriptions[channelId] = value); // set values for all checkboxes within the app
    debounce = window.setTimeout(() => {
        const notificationTypesIds = notificationTypes.value.filter((notificationType) => // filter out notification-type ids
            notificationType.relationships.application.data?.id === appId).map((type) => type.id);

        let subscriptionIds: Array<string> = [] // create array of subscription ids for deleting subscriptions within selected app
        notificationTypesIds.forEach((typeId) => subscriptionIds.push(getSubscriptionId(typeId, channelId)))

        if (isAllSelectedInApp && !value) {
            void deleteSubscriptions(subscriptionIds);
        } else {
            void createSubscriptions(notificationTypesIds, channelId);
        }
    }, 200);
};

// SUBSCRIPTIONS.
// data
const subscriptions = ref<Array<PSubscription>>([]);

// methods
const fetchSubscriptions = async (): Promise<void> => {
    try {
        let doc = await useRest().rest.subscriptions.self();
        if (doc) {
            subscriptions.value = doc.data as PSubscription[];
            let next;
            while (next = await doc?.next()) {
                doc = next;
                next.data.forEach((item: any)=> subscriptions.value.push(item));
            }
        }
    } catch (error: unknown) {
        logger.error(error);
    }
};

const getSubscriptionId = (typeId: string, channelId: string): string =>  [channelId, typeId, user].join('|');

const hasThisSubscription = (subscriptionId: string): boolean =>  subscriptions.value.some((subscription) => subscription.id === subscriptionId)

const createSubscriptions = async (typeIds: Array<string>, channelId: string): Promise<void> => {
    try {
        const promises: Array<Promise<any>> = [];
        for (const typeId of typeIds) {
            if (!hasThisSubscription(getSubscriptionId(typeId, channelId))) { // check that subscription doesn't already exists
                const data: PartialResource<PSubscription> = {
                    type: 'subscriptions',
                    relationships: {
                        'notification-type': {
                            data: {
                                id: typeId,
                                type: 'notification-types'
                            }
                        },
                        channel: {
                            data: {
                                id: channelId,
                                type: 'channels'
                            }
                        }
                    }
                };
                promises.push(useRest().rest.subscriptions.create(data));
            }
        }
        const responses = await Promise.all(promises);
        responses.forEach((promise) => {
            if (promise.status === 'rejected') {
                console.error(promise.reason);
            }
        });
    } catch (error: unknown) {
        logger.error(error);
    } finally {
        await fetchSubscriptions();
    }
};

const deleteSubscriptions = async (subscriptionIds: Array<string>): Promise<void> => {
    let ids: Array<string> = []
    subscriptions.value.forEach(s => ids.push(s.id))
    try {
        if (!subscriptionIds.length) return;
        const promises: Array<Promise<any>> = [];
        for (const subscriptionId of subscriptionIds) {

            let subscription = subscriptions.value.find((s) => s.id == subscriptionId)

            if (!subscription) {
                throw new Error(`Unable to find subscription ${subscription}`)
            }

            let cm = subscription.delete()
            promises.push(cm);
            subscriptions.value = subscriptions.value.filter((sub) => sub.id != subscriptionId);
        }
        const responses = await Promise.allSettled(promises);
        responses.forEach((promise) => {
            if (promise.status === 'rejected') {
                console.error(promise.reason);
            }
        });
    } catch (error: unknown) {
        logger.error(error);
        await fetchSubscriptions();
    }
};

const updateSubscription = (value: boolean, typeId: string, channelId: string, subscriptionId: string): void => {
    window.clearTimeout(debounce);
    debounce = window.setTimeout(() => {
        if (value) {
            void createSubscriptions([typeId], channelId);
        } else {
            void deleteSubscriptions([subscriptionId]);
        }
    }, 200);
};

// NOTIFICATION CHANNELS
// data
const notificationChannels = ref<Array<PChannel>>([]);
const channelIcons: any = {
    [Channel.Email]: mdiEmailOutline,
    [Channel.InApp]: mdiBellOutline,
    [Channel.Mobile]: mdiCellphone
};

// methods
const fetchChannels = async (): Promise<void> => {
    try {
        let doc = await useRest().rest.channels.self();
        if (doc){
            notificationChannels.value = doc.data as PChannel[];
            let next;
            while (next = await doc?.next()) {
                doc = next;
                next.data.forEach((item: any) => notificationChannels.value.push(item));
            }
        }
    } catch (error: unknown) {
        logger.error(error);
    }
};

// NOTIFICATION TYPES
// data
const notificationTypes = ref<Array<PNotificationType>>([]);

// methods
const fetchApplicationNotificationTypes = async (app: PApplication): Promise<void> => {
    try {
        const cm = app.relationships['notification-types']
        let doc = await cm.related();
        if (doc) {
             notificationTypes.value.push(...doc.data);

             let next;
             while (next = await doc?.next()) {
                 doc = next;
                 next.data.forEach((item: any) => notificationTypes.value.push(item));
            }
        }
    } catch (error: unknown) {
        logger.error(error);
    }
};

// APPLICATION
// data
const applications = ref<Array<PApplication>>([]);

// methods
const listApplications = async (): Promise<void> => {
    try {
        let doc = await useRest().rest.applications.self();
        if (doc) {
            applications.value = doc.data as PApplication[];

            let next;
            while (next = await doc?.next()) {
                doc = next;
                next.data.forEach((item: any) => applications.value.push(item));
            }
        }
    } catch (error: unknown) {
        logger.error(error);
    }
};

// init
const init = async (): Promise<void> => {
    try {
        isLoading.value = true;
        logger = useLogger(props.debug);
        user = props.userName;

        // fetch applications and their notification types
        await listApplications()
        applications.value.forEach(async (app) => {
            await fetchApplicationNotificationTypes(app)
        })

        // fetch channels and subscriptions
        await Promise.allSettled([
            fetchChannels(),
            fetchSubscriptions()
        ]);

        // set data for notifications settings table
        applications.value.forEach((application) => {
            const notificationSettingItemsTypes: Record<string, NotificationSettingItemType> = {};
            notificationTypes.value.forEach((type) => { // types
                if (application.id === type.relationships.application.data?.id) {

                    const notificationSettingItemsTypeSubscription: Record<string, boolean> = {};

                    let subscriptionIds: Array<string> = []
                    subscriptions.value.forEach((s) => subscriptionIds.push(s.id))

                    notificationChannels.value.forEach((channel) => { // channels
                        const value = subscriptionIds.includes(getSubscriptionId(type.id, channel.id)) // check if the subscription exists
                        notificationSettingItemsTypeSubscription[channel.id] = value; // set value (boolean) for subscription checkboxes
                    });

                    notificationSettingItemsTypes[type.id] = { // obj for applications notification type
                        appId: type.relationships.application.data!.id,
                        description: type.attributes.description,
                        name: type.attributes.displayName === "" ? type.attributes.name : type.attributes.displayName,
                        subscriptions: notificationSettingItemsTypeSubscription,
                        typeId: type.id
                    };
                }
            });

            notificationSettingsItems[application.id] = { // set obj for each application (former category)
                img: application.attributes.image,
                appId: application.id,
                description: application.attributes.description,
                name: application.attributes.displayName === "" ? application.attributes.name : application.attributes.displayName,
                types: notificationSettingItemsTypes,
            };
        });
    } catch (error: unknown) {
        logger.error(error);
    } finally {
        isLoading.value = false;
    }
};

// Hooks
onMounted(async() => {
    await init();
});

</script>

<style>
.center .v-selection-control {
    margin: auto;
    text-align: center;
    vertical-align: center;
    justify-content: center;
}
</style>
