

export enum NotificationChannelName { // TODO - smazat, až se upraví attributes.name
    Email = 'E-Mail',
    InApp = 'In-App',
    Mobile = 'SMS'
}

export enum Channel {
    InApp = 'inApp',
    Email = 'email',
    Mobile = 'mobile'
}
