import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import { useAuth } from '@/stores/useAuth';
import { useRest } from '@/stores/useRest';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            component: HomeView,
            name: 'home',
            path: '/'
        }
    ],
    sensitive: true,
    strict: true
});

router.beforeEach(async (_to, _from, next) => {
    const auth = useAuth();
    const api = useRest();
    try {
        if (!auth.authorized) {
            await auth.login();
        }

        if (!api.ready) {
            await api.init();
        }

    } catch (error: unknown) {
        console.error(error)
    }
    next();
})

export default router;
